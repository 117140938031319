
import Vue, { PropOptions } from "vue"; // @ts-ignore
import { Collection, Strapi } from "~/types/typings";

export default Vue.extend({
  components: {
    Collection_Cases: () => import("~/components/zone/Collection_Cases.vue"),
    Collection_Cases_Logos: () => import("~/components/zone/Collection_Cases_Logos.vue"),
    Collection_Cases_Random_Quote: () =>
      import("~/components/zone/Collection_Cases_Random_Quote.vue"),
    Collection_Employees: () => import("~/components/zone/Collection_Employees.vue"),
    Collection_Blog: () => import("~/components/zone/Collection_Blog.vue"),
    Collection_Pages: () => import("~/components/zone/Collection_Pages.vue"),
  },
  props: {
    collection: {
      type: Object,
      required: true,
    } as PropOptions<Collection>,
    $strapi: {
      type: Object,
      required: false,
    } as PropOptions<Strapi>,
  },
  data() {
    return {
      items: [],
    };
  },
  async fetch(): Promise<any> {
    interface FilterQuery {
      limit: Number | null;
      sort: String | null;
      direction: String | null;
      special: String | null;
      tags: String | null;
    }
    let filterQuery: FilterQuery = {
      limit: this.collection.Limiet,
      sort: this.collection.Sorting,
      direction: this.collection.Direction,
      special: this.collection.Special,
      tags: null,
    };
 
    //verschil naamgeving strapi4
    if(this.collection.CollectionType==="klanten") {
      this.collection.CollectionType="clients";
    }
    //verschil naamgeving strapi4
    if(this.collection.CollectionType==="paginas") {
      this.collection.CollectionType="pages";
    }
    //verschil naamgeving strapi4
    if(this.collection.CollectionType==="medewerkers") {
      this.collection.CollectionType="employees";
    }
    if(this.collection.CollectionType ==="cases" && this.getLayout(this.collection) === 'cases_websites') {
      filterQuery.tags = "websites";
    }
    if(this.collection.CollectionType ==="cases" && this.getLayout(this.collection) === 'cases_webshops') {
      filterQuery.tags = "webshops";
    }
    if(this.collection.CollectionType ==="cases" && this.getLayout(this.collection) === 'cases_webapplicaties') {
      filterQuery.tags = "webapplicaties";
    }
    
    let filter = this.buildGraphQLFilter(filterQuery);
    let collectionFields = this.getQueryFields(this.collection.CollectionType);
    let querySubject = this.collection.CollectionType;

    //verschil naamgeving strapi4
    if(querySubject=="paginas") {
      querySubject="pages";
    }
    //verschil naamgeving strapi4
    if(querySubject=="medewerkers") {
      querySubject="employees";
    }

    if (filter !== "") {
      querySubject += " " + filter;
    }

    let kind: any = this.collection.CollectionType;

    this.items = await this.$nuxt.context.$strapi
      .graphql({
        query:
          `
          query {
            ` + querySubject + ` {
              data {
                id
                attributes {
                  Title
                  Slug
                  Created
                  Special
                  Intro {
                    Text
                    Image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    Color
                    Animation
                  }
                  ` + collectionFields + `
                }
              }
            }
          }
        `,
      })
      .then((response: any) => response[kind]);
  },
  methods: {
    getQueryFields(type: String | null): string {
      let queryFields = "";

      switch (type) {
        case "cases":
          queryFields += `
              Tags
              Quote {
                Title
                Function
                Text
              }
              Client {
                data {
                  attributes {
                    Title
                  }
                }
              }
              Technieken {
                Techniek
              }
          `;
          break;
        case "clients":
          queryFields += `
            Tags
            Logo {
              data {
                attributes {
                  url
                }
              }
            }
          `;
          break;
        case "employees":
          queryFields += `
            Image {
              data {
                attributes {
                  url
                }
              }
            }
            Function
            Mail
            Phone
            LinkedIn
            Interests
            HoverImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          `;
          break;
      }
      
      return queryFields;
    },
    buildGraphQLFilter(filterVars: {
      limit: Number | null;
      sort: String | null;
      direction: String | null;
      special: String | null;
      tags: String | null;
    }) {
        let filter = "(";
        let filterCounter = 0;

        // Verwerking voor "limit"
        if (filterVars.limit !== null && filterVars.limit !== 0) {
            filter += `pagination: { limit: ${filterVars.limit} }`;
            filterCounter++;
        }

        // Verwerking voor "filters"
        let filters = "filters: {";
        let filtersCounter = 0;

        // Verwerking voor "special"
        if (filterVars.special === "Special" || filterVars.special === "NonSpecial") {
            if (filtersCounter > 0) {
                filters += ", ";
            }
            filters += `Special: { eq: ${filterVars.special === "Special"} }`;
            filtersCounter++;
        }

        // Verwerking voor "tags"
        if (filterVars.tags !== null) {
            if (filtersCounter > 0) {
                filters += ", ";
            }
            filters += `Tags: { eq: "${filterVars.tags}" }`;
            filtersCounter++;
        }

        // Sluit het filters object af
        filters += "}";

        // Voeg filters toe aan het hoofdfilter als er filters zijn
        if (filtersCounter > 0) {
            if (filterCounter > 0) {
                filter += ", ";
            }
            filter += filters;
            filterCounter++;
        }

        // Verwerking voor "sort"
        if (filterVars.sort !== null) {
            if (filterCounter > 0) {
                filter += ", ";
            }

            let sortField;
            switch (filterVars.sort) {
                case "titel":
                    sortField = "Title";
                    break;
                case "datum":
                    sortField = "Created";
                    break;
                case "volgorde":
                default:
                    sortField = "id";
            }

            filter += `sort: "${sortField}:`;

            if (filterVars.direction !== null) {
                filter += filterVars.direction;
            } else {
                filter += "desc";
            }
            filter += '"';
        }

        filter += ")";

        return filter;
    },

    getLayout: (item: Collection): string => {
      return item.AlternativeLayout === null || item.AlternativeLayout === "none"
        ? item.CollectionType as string
        : item.AlternativeLayout as string;
    },
  },
});
